import React, {FC} from "react"
import styled from "@emotion/styled";
import plotImage from "../../images/plot-airten-full.webp"
import plotImageLeft from "../../images/plot22-left.webp"
import plotImageRight from "../../images/plot22-right.webp"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: black;
  color: white;
  background-size: contain;

  @media screen and (min-width: 860px) {
    background-image: url(${plotImage});
    background-repeat: no-repeat;
    background-position: center;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  * {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  margin: 0 auto;

  @media screen and (max-width: 860px) {
    background-image: url(${plotImageLeft});
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    width: 100%;
    max-width: 100%;
  }
`

const TestimonialContainer = styled.div`
padding: 25px;
max-width: 500px;
margin: 0 auto;

@media screen and (max-width: 860px) {
  background-image: url(${plotImageRight});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  width: 100%;
  max-width: 100%;
}

`;

const Title = styled.h3`
  font-size: 52px;
`;

const Subtitle = styled.h3`
  font-size: 32px;
`;


const Plot22: FC = () => {
  return <Container>
    <ContentContainer>
      <DescriptionContainer>
        <Title>Plot 22</Title>
        <Subtitle>Small Music Venue</Subtitle>
        <ul>
          <li>Installed Full Void Sound System</li>
          <li>Full setup with measurements including removing room vibrations</li>
        </ul>
      </DescriptionContainer>
      <TestimonialContainer>
        <p><b>Audio Engineering and Installation have been a pleasure to work with! Taking on board our requirements as a small venue with a history of noise complaints, they were able to provide us with a system bespoke to our needs, still providing the right amount of volume in the space while ensuring levels are not too loud for patrons both inside and outside of the venue.</b></p>
        <p><b>I would definitely recommend working with Audio Engineering and Installation!</b></p>
        <p><b>- Mike</b> Owner of Plot 22</p>
      </TestimonialContainer>
    </ContentContainer>
  </Container>
}

export default Plot22;