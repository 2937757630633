import React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/Layout"
import TopContent from "../components/TopContent";
import BrandBar from "../components/BrandBar";
import Consultation from "../components/Consulation";
import CaseStudies from "../components/CaseStudies/CaseStudies";
import ProjectLifecycle from "../components/ProjectLifecycle";
import Google from "../components/Google";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  return (
    <>
      <Google />
      <Layout>
        <TopContent />
        <CaseStudies />
        <Consultation />
        <BrandBar />
        <ProjectLifecycle />
      </Layout>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO title={"Professional Sound System Installation"} path={"/"} />
