import React, {FC} from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 12px;
`;

const Paragraph = styled.p`
  margin-bottom: 12px;
`;

const Consultation: FC = () => {
  return <Container>
    <ContentContainer>
      <h2>Get in touch for a free consultation</h2>
      <Paragraph>We are happy to help. Please get in touch to find out how we can make your life easier.</Paragraph>
      <p><b>info@audioinstalls.co.uk</b></p>
      <p><b>0114 308 2124</b></p>
    </ContentContainer>
  </Container>
}

export default Consultation;